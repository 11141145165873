import { Router } from '@reach/router';
import Layout from 'components/Layout';
import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import Invoices from 'cashier/Invoices';

export default (props) => (
  <Layout {...props}>
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Router>
        <Invoices path="/cashier/invoices/:assignmentId" />
      </Router>
    </ReactQueryConfigProvider>
  </Layout>
);
