import { LinearProgress } from '@material-ui/core';
import InvoicesTable from 'cashier/InvoicesTable';
import { saveAs } from 'file-saver';
import React, { useRef } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSnackbar } from 'utils/customHooks';
import fetch from 'utils/fetch';
import { formatDateHumanReadable } from 'utils/utils';

function Invoices({ assignmentId }) {
  const [notif] = useSnackbar();
  const progressText = useRef('');

  const { data, status, refetch: refetchInvoices } = useQuery(
    assignmentId && ['sales_cashier_invoices', { assignmentId }],
    () => fetch(`/delivery/salesCashier/assignment/${assignmentId}`)
  );

  const [downloadExcel] = useMutation(
    () =>
      fetch({
        url: `/delivery/salesCashier/report?assignmentId=${assignmentId}`,
        method: 'GET',
        responseType: 'blob',
        wholeResponse: true,
        headers: {
          'Content-Type': 'application/json, blob',
        },
      }),
    {
      onSuccess: (data) => {
        saveAs(
          data.data,
          data.headers.filename ? data.headers.filename : `Invoices_${assignmentId}.xlsx`
        );
      },
      onError: () => {
        notif('Download failed', { variant: 'error', autoHideDuration: 1000 });
      },
      onSettled: () => {
        progressText.current = '';
      },
    }
  );

  const [invoicesAction] = useMutation(
    () =>
      fetch({
        method: 'POST',
        url: `/delivery/salesCashier/${
          salesCashierApproved ? 'unapprove' : 'approve'
        }?assignmentId=${assignmentId}`,
      }),
    {
      onSuccess: () => refetchInvoices(),
    }
  );

  if (!data || status === 'loading') return <LinearProgress style={{ width: '100%' }} />;

  const { deliveryAgent, picklist, invoices, salesCashierApproved } = data;

  return (
    <div class="bg-salesGray p-2">
      <div class="flex justify-between font-semibold mx-2">
        <p>
          Delivery Agent : {deliveryAgent.name} ({deliveryAgent.loginId})
        </p>
        <p>Delivery Date : {data.date}</p>
        <p>
          Picklist: {picklist.name} {formatDateHumanReadable(picklist.date)}
        </p>
      </div>
      {invoices.length > 0 ? <InvoicesTable data={data} /> : null}
      <div class="flex justify-evenly mt-3">
        <button
          onClick={downloadExcel}
          class="bg-green-500 text-white p-1 px-2 rounded-lg font-semibold text-md"
        >
          Download Excel
        </button>
        <button
          onClick={invoicesAction}
          class={`${
            salesCashierApproved ? '' : 'bg-theme text-white p-1 px-2 rounded-md'
          } font-semibold`}
        >
          {salesCashierApproved ? 'Unapprove' : 'Approve'}
        </button>
      </div>
    </div>
  );
}
export default Invoices;
