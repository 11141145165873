import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ImageIcon from '@material-ui/icons/Image';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import React, { useState } from 'react';
import { truncate } from 'utils/utils';

const columns = [
  {
    id: 'sellerCode',
    label: 'Seller Code',
    align: 'center',
    width: 35,
    format: (value) => truncate(value, 12),
  },
  {
    id: 'sellerName',
    label: 'Seller Name',
    align: 'center',
    width: 45,
    format: (value) => truncate(value, 16),
  },
  {
    id: 'storeCode',
    label: 'Store Code',
    format: (value) => truncate(value, 16),
    width: 40,
    align: 'center',
  },
  {
    id: 'storeName',
    label: 'Store Name',
    format: (value) => truncate(value, 16),
    width: 55,
    align: 'center',
  },
  {
    id: 'invoiceNo',
    label: 'Invoice No.',
    width: 60,
    align: 'center',
  },
  { id: 'invoiceDate', label: 'Invoice Date', width: 40, align: 'center' },
  {
    id: 'invoiceVal',
    label: 'Invoice Value',
    width: 40,
    align: 'center',
  },
  { id: 'deliveryState', label: 'Delivery State', align: 'center', width: 40 },
  { id: 'payMode', label: 'Payment Mode', width: 45, align: 'center' },
  {
    id: 'cashAmt',
    label: 'Cash Amt',
    width: 30,
    align: 'right',
    marginRight: 3,
  },
  {
    id: 'chequeAmt',
    label: 'Cheque Amt',
    width: 30,
    align: 'right',
    marginRight: 3,
  },
  {
    id: 'upiAmt',
    label: 'UPI Amt',
    width: 30,
    align: 'right',
    marginRight: 3,
  },
  {
    id: 'arAmt',
    label: 'A/R Amt',
    width: 30,
    align: 'right',
    marginRight: 3,
  },
  {
    id: 'srnVal',
    label: 'SRN Value',
    width: 30,
    align: 'right',
    marginRight: 3,
  },
  { id: 'approved', label: 'Approved', width: 40, align: 'center' },
  { id: 'links', label: 'POD', width: 30, align: 'center' },
  { id: 'checked', label: 'Checked', width: 30, align: 'center' },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '78vh',
  },
});

function createLastRow(invoices, arAmtCount) {
  const invoiceTotal = invoices.reduce((a, b) => ({ value: a.value + b.value }));
  const invoiceVal = invoiceTotal.value;
  const cashAmt =
    invoices
      .map((invoice) => invoice?.payments?.filter((p) => p.modeOfPayment === 'CASH'))
      .filter((invoice) => invoice.length > 0).length > 0
      ? invoices
          .map((invoice) => invoice?.payments?.filter((p) => p.modeOfPayment === 'CASH'))
          .filter((invoice) => invoice.length > 0)
          .map((cash) => cash[0].amountCollected)
          .reduce((a = 0, c) => a + c)
      : '--';
  const chequeTotal =
    invoices
      .map((invoice) => invoice?.payments?.filter((p) => p.modeOfPayment === 'CHEQUE'))
      .filter((invoice) => invoice.length > 0).length > 0
      ? invoices
          .map((invoice) => invoice?.payments?.filter((p) => p.modeOfPayment === 'CHEQUE'))
          .filter((invoice) => invoice.length > 0)
          ?.map((cash) => cash[0].amountCollected)
          ?.reduce((a = 0, c) => a + c)
      : '--';
  const chequeAmt =
    chequeTotal !== '--'
      ? `${chequeTotal} (${
          invoices
            .map((invoice) => invoice?.payments?.filter((p) => p.modeOfPayment === 'CHEQUE'))
            .filter((invoice) => invoice.length > 0).length
        })`
      : '--';
  const upiTotal =
    invoices
      .map((invoice) =>
        invoice?.payments?.filter(
          (p) => p.modeOfPayment === 'UPI' && p.modeDetails.upiTrxStatus === 'success'
        )
      )
      .filter((invoice) => invoice.length > 0).length > 0
      ? invoices
          .map((invoice) =>
            invoice?.payments?.filter(
              (p) => p.modeOfPayment === 'UPI' && p.modeDetails.upiTrxStatus === 'success'
            )
          )
          .filter((invoice) => invoice.length > 0)
          .map((cash) => cash[0].amountCollected)
          .reduce((a = 0, c) => a + c)
      : '--';
  const upiAmt =
    upiTotal !== '--'
      ? `(${
          invoices
            .map((invoice) =>
              invoice?.payments?.filter(
                (p) => p.modeOfPayment === 'UPI' && p.modeDetails.upiTrxStatus === 'success'
              )
            )
            .filter((invoice) => invoice.length > 0).length
        })`
      : '--';
  const invoiceNo = `${invoices.length} in total`;
  const srnValTotal = invoices.map((invoice) => invoice.srnValue).reduce((a = 0, c) => a + c);
  const srnVal =
    srnValTotal !== 0
      ? `${srnValTotal} (${invoices.filter((invoice) => invoice.srnValue > 0).length})`
      : '--';
  const damageReturn = invoices.map((invoice) => invoice.damageReturn).reduce((a = 0, c) => a + c);
  const arTotal =
    invoiceVal -
    srnValTotal -
    (damageReturn || 0) -
    (cashAmt !== '--' ? cashAmt : 0) -
    (chequeTotal !== '--' ? chequeTotal : 0) -
    (upiTotal !== '--' ? upiTotal : 0);
  const arAmt = arTotal ? `${arTotal} (${arAmtCount.length})` : '--';
  return {
    invoiceNo,
    invoiceVal,
    cashAmt,
    chequeAmt,
    upiAmt,
    arAmt,
    srnVal,
  };
}

function InvoicesTable({ data }) {
  const classes = useStyles();
  const [invoiceIds, setInvoiceIds] = useState([]);
  const { invoices } = data;
  let arAmtCount = [];
  const rows = invoices.map((invoice) =>
    createData(
      invoice.id,
      invoice.salesman?.name,
      invoice.salesman?.code,
      invoice.retailer.code,
      invoice.retailer.name,
      invoice.code,
      invoice.date,
      invoice.value,
      invoice.state,
      invoice?.payments?.map((payment) => payment.modeOfPayment),
      invoice?.payments?.filter((p) => p.modeOfPayment === 'CASH').length > 0
        ? invoice?.payments
            ?.filter((p) => p.modeOfPayment === 'CASH')
            ?.map((p) => p.amountCollected)
            ?.reduce((a = 0, c) => a + c)
        : '--',
      invoice?.payments?.filter((p) => p.modeOfPayment === 'CHEQUE').length > 0
        ? invoice?.payments
            ?.filter((p) => p.modeOfPayment === 'CHEQUE')
            ?.map((p) => p.amountCollected)
            ?.reduce((a = 0, c) => a + c)
        : '--',
      invoice?.payments?.filter((p) => p.modeOfPayment === 'UPI').length > 0
        ? invoice?.payments
            ?.filter((p) => p.modeOfPayment === 'UPI' && p.modeDetails.upiTrxStatus === 'success')
            ?.map((p) => p.amountCollected)
            ?.reduce((a = 0, c) => a + c)
        : '--',
      invoice.value,
      invoice.srnValue || '--',
      invoice.damageReturn,
      invoice.payments?.filter(
        (p) =>
          p.modeOfPayment === 'CASH' ||
          p.modeOfPayment === 'CHEQUE' ||
          (p.modeOfPayment === 'UPI' && p.modeDetails.upiTrxStatus === 'success')
      ).length > 0
        ? invoice.payments
            ?.filter(
              (p) =>
                p.modeOfPayment === 'CASH' ||
                p.modeOfPayment === 'CHEQUE' ||
                (p.modeOfPayment === 'UPI' && p.modeDetails.upiTrxStatus === 'success')
            )
            .map((p) => p.amountCollected)
            .reduce((a = 0, c) => a + c)
        : 0,
      invoice.pod.images,
      invoice.salesCashierApproved,
      invoice.returnState,
      arAmtCount
    )
  );

  rows.push(createLastRow(invoices, arAmtCount));

  const approveInvoice = (id) => {
    if (invoiceIds.includes(id)) setInvoiceIds(invoiceIds.filter((invoiceId) => invoiceId !== id));
    else setInvoiceIds([...invoiceIds, id]);
  };

  const openImgs = (imgsLinks) => {
    if (imgsLinks?.length > 0 && imgsLinks[0]) imgsLinks.map((imgLink) => window.open(imgLink));
  };
  function createData(
    id,
    sellerName,
    sellerCode,
    storeCode,
    storeName,
    invoiceNo,
    invoiceDate,
    invoiceVal,
    delivery_state,
    paymentMode,
    cashAmt,
    chequeAmt,
    upiAmt,
    value,
    srnVal,
    damageReturn,
    amtCollected,
    imgsLink,
    salesCashierApproved,
    returnState,
    arAmtCount
  ) {
    const arAmtVal =
      value - (srnVal !== '--' ? srnVal : 0) - (damageReturn || 0) - (amtCollected || 0);
    const arAmt = arAmtVal || '--';

    if (arAmtVal !== 0) {
      paymentMode.push('CREDIT');
      arAmtCount.push(invoiceNo);
    }
    const payMode = paymentMode.join(' & ');
    const links = (
      <div className="cursor-pointer" onClick={() => openImgs(imgsLink)}>
        {imgsLink?.length > 1 ? (
          <PhotoLibraryIcon style={{ color: 'green' }} />
        ) : (
          <ImageIcon
            style={!imgsLink?.length || !imgsLink[0] ? { color: '#07162a' } : { color: 'green' }}
          />
        )}
      </div>
    );
    const approved = salesCashierApproved ? (
      <CheckCircleIcon className="cursor-pointer" style={{ color: 'green' }} />
    ) : null;
    const checked = invoiceIds.includes(id) ? (
      <CheckBoxIcon
        onClick={() => approveInvoice(id)}
        style={{ color: 'green' }}
        className="cursor-pointer"
      />
    ) : (
      <CheckBoxOutlineBlankIcon onClick={() => approveInvoice(id)} />
    );
    const deliveryState = delivery_state === 'FAILED' ? returnState : delivery_state;
    return {
      sellerName,
      sellerCode,
      storeCode,
      storeName,
      invoiceNo,
      invoiceDate,
      invoiceVal,
      deliveryState,
      payMode,
      cashAmt,
      chequeAmt,
      upiAmt,
      arAmt,
      srnVal,
      approved,
      links,
      checked,
    };
  }

  return (
    <TableContainer className={`${classes.container} mt-2`}>
      <Table
        stickyHeader
        aria-label="invoices table"
        style={{ border: '1px solid black', padding: 0 }}
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                style={{
                  border: '1px solid black',
                  maxWidth: column.width,
                  padding: 0,
                  paddingRight: column.marginRight ? 2 : 0,
                }}
                key={column.id}
                align={column.align}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.invoiceId || 'Last_row'}>
                {columns.map((column) => {
                  const value = row[column.id] || '';
                  return (
                    <>
                      {column.format ? (
                        <Tooltip title={value} aria-label="add" placement="top-start">
                          <TableCell
                            style={{
                              border: '1px solid black',
                              maxWidth: column.width,
                              whiteSpace: 'normal',
                              wordWrap: 'break-word',
                              padding: 0,
                              paddingRight: column.marginRight ? column.marginRight : 0,
                            }}
                            key={column.id}
                            align={column.align}
                          >
                            {column.format(value)}
                          </TableCell>
                        </Tooltip>
                      ) : (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            border: '1px solid black',
                            maxWidth: column.width,
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            padding: 0,
                            paddingRight: column.marginRight ? column.marginRight : 0,
                          }}
                        >
                          {value}
                        </TableCell>
                      )}
                    </>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default InvoicesTable;
